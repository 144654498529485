import {Auth} from '@verily-src/auth';
import {
  BundleStatus,
  BundleUtility,
  Logger,
  type Step,
} from '@verily-src/bundle-utility';
import {Nav, NavItem} from '@verily-src/nav';
import {ApplicationSuite, ThemeProps} from '@verily-src/phaf-runtime-helpers';
import {UnifiedApi} from '@verily-src/phaf-unified-api';
import {
  Bundle,
  BundleStep,
  StepStatus,
  StepType,
} from '@verily-src/verily1-protos/verily-me/web/bundle/bff/api/v1/bundle_service';
import {getVerilyMeTheme} from './verily-me-theme-provider/verily-me-theme-provider';

class VerilyMeApi implements UnifiedApi {
  constructor() {}

  // General PHAF Unified API functions:
  /**
   * Single nav instance to be used throughout VerilyMe Web MFEs
   * @returns Singular VerilyMe Web Nav instance
   */
  get nav(): typeof Nav {
    return Nav;
  }

  /**
   * Single auth instance to be used throughout VerilyMe Web MFEs
   * @returns Singular VerilyMe Web Auth instance
   */
  get auth(): typeof Auth {
    return Auth;
  }

  /**
   * Indicates whether user has access to given MFE
   * @returns if user has access to given MFE
   */
  async hasAccess() {
    return true;
  }

  /**
   * The application suite the MFE is running in
   * @returns VerilyMe every time
   */
  getApplicationSuite() {
    return ApplicationSuite.VERILY_ME;
  }

  /**
   * Accessing the Bundle Utility for MFEs to handle deeplinking.
   * @returns VerilyMeBundleUtility class
   */
  get bundle() {
    return BundleUtility;
  }

  /**
   * Accessing the Logger for MFEs to log errors.
   * Note: this is an MVP-only solution. Post-MVP this will be moved to PHAF.
   */
  get logger(): typeof Logger {
    return Logger;
  }

  get theme(): ThemeProps {
    return getVerilyMeTheme();
  }
}

// Anything exported from this file is importable by other in-browser modules.
const api = new VerilyMeApi();
export {
  api,
  type VerilyMeApi,
  Bundle,
  BundleStatus,
  BundleStep,
  NavItem,
  Step,
  StepStatus,
  StepType,
};
