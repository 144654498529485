import {config, ThemeProps} from '@verily-src/phaf-runtime-helpers';
import {useDarkMode, useLocale} from '@verily-src/react-design-system';

export function getVerilyMeTheme(): ThemeProps {
  const isDarkModeEnabled = config.getBoolean('FEATURE_DARK_MODE_ENABLED');
  const isVDSThemeV2Enabled = config.getBoolean('FEATURE_VDS_THEME_V2_ENABLED');

  const prefersDarkMode = useDarkMode();
  const locale = useLocale();

  const DarkTheme = isVDSThemeV2Enabled
    ? 'ConsumerModernDarkTheme'
    : 'ConsumerDarkTheme';
  const LightTheme = isVDSThemeV2Enabled
    ? 'ConsumerModernLightTheme'
    : 'ConsumerLightTheme';

  return {
    theme: isDarkModeEnabled && prefersDarkMode ? DarkTheme : LightTheme,
    locale: locale,
  };
}
